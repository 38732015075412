import React from 'react';
import './styles/_background.scss';

const Background = () => {
    return (
        <div className="background"></div>
    );
}

export default Background;
